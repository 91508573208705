import { Link } from '@remix-run/react'
import camelCase from 'lodash/camelCase'
import { FC, MouseEvent } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Bem, checkIfTradepubLink } from '@scm/ui-core'

import { ButtonProps } from './type'

const button = Bem('Button')

// eslint-disable-next-line complexity
export const Button: FC<ButtonProps> = props => {
  const {
    type = 'button',
    target,
    url,
    variant,
    disabled,
    children,
    leftIcon,
    rightIcon,
    className,
    textClassName,
    buttonLikeAnchor = false,
    dataName,
    dataActionType,
    automationId,
    onClick,
    trackGtmClick = () => {},
    ...buttonProps
  } = props

  const rootStyles = button(
    {
      size: props.size,
      variant: camelCase(props.variant),
      disabled,
    },
    [className],
  )

  const content = (
    <>
      {leftIcon && (
        <Icon
          icon={leftIcon}
          className={button('Icon', { position: 'left' })}
        />
      )}

      <span className={button('ContentWrapper', [textClassName])}>
        {children}
      </span>

      {rightIcon && (
        <Icon
          icon={rightIcon}
          className={button('Icon', { position: 'right' })}
        />
      )}
    </>
  )

  if (buttonLikeAnchor) {
    const isTradepubLink = checkIfTradepubLink(url as string)
    const rel = isTradepubLink ? 'nofollow' : undefined
    const href = url ? encodeURI(url) : ''

    return (
      <a
        data-component="button"
        data-testid={automationId ?? 'button_a'}
        className={rootStyles}
        href={href}
        target={target}
        rel={rel}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          trackGtmClick && trackGtmClick(href, e)
          onClick && onClick()
        }}
        data-name={dataName}
        data-action-type={dataActionType}
      >
        {content}
      </a>
    )
  }

  if (variant === 'text-link') {
    if (!url) {
      return (
        <span
          {...buttonProps}
          data-component="button"
          role="presentation"
          onClick={() => !disabled && onClick && onClick()}
          className={rootStyles}
        >
          {content}
        </span>
      )
    }
    const to = url ? encodeURI(url) : ''

    return (
      <Link
        {...buttonProps}
        data-component="button"
        className={rootStyles}
        target={target}
        rel={target == '_blank' ? 'noreferrer' : ''}
        to={to}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => trackGtmClick(to, e)}
      >
        {content}
      </Link>
    )
  }

  return (
    <button
      {...buttonProps}
      data-component="button"
      data-testid={automationId ?? 'button_button'}
      className={rootStyles}
      disabled={disabled}
      onClick={() => !disabled && onClick && onClick()}
      type={type}
    >
      {content}
    </button>
  )
}
